exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-carousel-tsx": () => import("./../../../src/pages/carousel.tsx" /* webpackChunkName: "component---src-pages-carousel-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-radixui-cursor-tsx": () => import("./../../../src/pages/radixui-cursor.tsx" /* webpackChunkName: "component---src-pages-radixui-cursor-tsx" */),
  "component---src-pages-wp-category-slug-m-tsx": () => import("./../../../src/pages/{wpCategory.slug}/m.tsx" /* webpackChunkName: "component---src-pages-wp-category-slug-m-tsx" */),
  "component---src-pages-wp-category-slug-tsx": () => import("./../../../src/pages/{wpCategory.slug}.tsx" /* webpackChunkName: "component---src-pages-wp-category-slug-tsx" */),
  "component---src-pages-wp-post-link-tsx": () => import("./../../../src/pages/{wpPost.link}.tsx" /* webpackChunkName: "component---src-pages-wp-post-link-tsx" */)
}

